var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"module-list__wrapper--course-details"},[_c('vle-accordion',{attrs:{"open":_vm.open,"module":_vm.module},on:{"update:open":function($event){_vm.open=$event},"toggle":_vm.onToggle,"update:module":function($event){_vm.module=$event}},scopedSlots:_vm._u([{key:"accordion-title",fn:function(){return [_c('div',{staticClass:"module-list__header",class:{ 'module-list__header--complete': _vm.module.complete }},[_c('div',{staticClass:"module-list__header-title"},[_vm._v(" "+_vm._s(_vm.module.title)+" ")]),_c('div',{staticClass:"module-list__header-sub-title"},[_c('small',[_vm._v(_vm._s(_vm.currentModuleNo)+" / "+_vm._s(_vm.totalModules))])])])]},proxy:true},{key:"accordion-body",fn:function(){return _vm._l((_vm.module.sequences),function(sequence,modIndex){return _c('div',{key:modIndex},[_c('div',{staticClass:"module-list__sub-header"},[_c('div',{staticClass:"module-list__sub-header-title"},[_vm._v(" "+_vm._s(sequence.title)+" ")])]),_c('div',{staticClass:"module-list__container"},[_c('div',{staticClass:"module-list__pb-wrapper"}),_c('ul',_vm._l((sequence.units),function(unit,seqIndex){return _c('li',{key:seqIndex,staticClass:"module-list__section",class:{
                'module-list__section--active':
                  _vm.getCurrentUnitId === unit.id && _vm.isEnable,
                'module-list__section--completed': unit.complete === true,
                'module-list__section--locked':
                  _vm.isPrerequisiteCompleted(sequence) !== true,
                'module-list__section--only-child':
                  _vm.module.sequences.length == 1,
                'module-list__section--not-enabled': !_vm.isEnable
              },attrs:{"id":_vm.isPrerequisiteCompleted(sequence) !== true
                  ? 'module-list__section--locked'
                  : 'module-list__section'},on:{"click":function($event){return _vm.selectModule(sequence, unit.id)}}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"sp__module-align align-self-center"},[(_vm.isPrerequisiteCompleted(sequence) !== true)?_c('img',{attrs:{"src":require('@/assets/images/module-list/unit-locked.svg'),"alt":"unit locked"}}):(unit.complete)?_c('img',{attrs:{"src":require('@/assets/images/module-list/unit-complete.svg'),"alt":"unit complete"}}):(_vm.getCurrentUnitId === unit.id)?_c('img',{staticClass:"rounded-circle",attrs:{"src":_vm.allProfile.profile_image_url
                        ? _vm.allProfile.profile_image_url
                        : require('@/assets/images/menu/avatar.svg'),"alt":"avatar","width":"24px","height":"24px","id":"profile-pic"}}):_c('img',{attrs:{"src":_vm.getUnitIcon(
                        unit.type,
                        _vm.getCurrentUnitId === unit.id && _vm.isEnable
                      ),"alt":unit.type}})]),_c('div',{attrs:{"id":_vm.isPrerequisiteCompleted(sequence) !== true
                      ? `tooltip-target-title-mod${modIndex}-seq${seqIndex}`
                      : ''}},[_vm._v(" "+_vm._s(unit.title)+" "),_c('div',{staticClass:"module-list__type"},[_vm._v(" "+_vm._s(_vm.getUnitType(unit.type))+" ")])])]),(_vm.getPrerequisiteCompleted(sequence))?_c('b-tooltip',{attrs:{"target":`tooltip-target-title-mod${modIndex}-seq${seqIndex}`}},[_vm._v(" "+_vm._s(_vm.$t("course.content_lock_alert"))+" "+_vm._s(_vm.getPrerequisiteCompleted(sequence))+" ")]):_vm._e()],1)}),0)])])})},proxy:true},(_vm.module.complete === true)?{key:_vm.dynamicSlotName,fn:function(){return [_c('div',{staticClass:"mb-2 d-flex justify-content-between"},[_c('div',{staticClass:"lx-section-completed"},[_vm._v(" "+_vm._s(_vm.$t("my_learning.tab.completed"))+" ")]),_c('div',[_c('img',{attrs:{"src":require("@/assets/images/course-details/check-circle-fill.svg"),"alt":"completed"}})])])]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }